<template>
	<div class="section">
		<div class="container">
			<h2 class="title is-2">Quote Report</h2>
			<div class="columns">
				<div class="column is-6">
					<dates v-model="dates"></dates>
				</div>
				<div class="column is-6">
					<!--<o-field label="Filters">

                    </o-field>-->
				</div>
			</div>

			<div class="columns">
				<div class="column is-12 rfq-actions">
					<div class="is-pulled-left">
						<o-button variant="primary" :disabled="loading" @click="RunReport">Run Report</o-button>
					</div>
					<div class="is-pulled-right">
						<o-button
							variant="success"
							icon-left="file-excel"
							:disabled="exporting"
							@click="Export"
							v-show="hasRan">
							Export
						</o-button>
					</div>
					<div class="select-wrapper">
						<o-field label="Filter by Job Boss">
							<o-select v-model="showJobBoss">
								<option value="All">All</option>
								<option value="JobBoss">Only Job Boss</option>
								<option value="NonJobBoss">Only Non Job Boss</option>
							</o-select>
						</o-field>
						<o-field label="Filter by previous customer">
							<o-select v-model="showPrevious">
								<option value="All">All</option>
								<option value="NonPrevious">Only new customers</option>
								<option value="Previous">Only returning customers</option>
							</o-select>
						</o-field>
						<o-field label="Show archive">
							<o-switch v-model="showArchived"></o-switch>
						</o-field>
						<o-button v-if="hasFilter" variant="warning" @click="ResetFilters">
							Reset Filters
						</o-button>
					</div>
				</div>
			</div>
			<div v-if="hasRan">
				<div>
					<o-table :data="filteredResults" :loading="loading" :key="key">
						<o-table-column field="Id" label="Id" sortable v-slot="props">
							<o-button variant="primary" inverted size="small" @click="OpenQuote(props.row.Id)">
								{{ props.row.DisplayId }}
							</o-button>
						</o-table-column>

						<o-table-column field="JobBoss" label="Job Boss" sortable v-slot="props">
							<o-field>
								<o-switch v-model="props.row.JobBoss" @input="jbToggle(props.row)"></o-switch>
							</o-field>
						</o-table-column>

						<o-table-column
							field="HasPreviousQuote"
							label="Previous Customer"
							sortable
							v-slot="props">
							<o-icon :icon="props.row.HasPreviousQuote ? 'check' : 'times'"></o-icon>
						</o-table-column>

						<o-table-column
							field="PreviousQuotes"
							label="Previous Requests"
							sortable
							v-slot="props">
							{{ props.row.PreviousQuotes }}
						</o-table-column>

						<o-table-column field="Company" label="Company" sortable searchable>
							<template v-slot:searchable="props">
								<o-input
									v-model="props.filters[props.column.field]"
									placeholder="Search.."
									icon="search"
									size="small"></o-input>
							</template>
							<template v-slot="props">
								{{ props.row.Company }}
							</template>
						</o-table-column>

						<o-table-column field="Architect" label="Architect" sortable searchable>
							<template v-slot:searchable="props">
								<o-input
									v-model="props.filters[props.column.field]"
									placeholder="Search.."
									icon="search"
									size="small"></o-input>
							</template>
							<template v-slot="props">
								{{ props.row.Architect }}
							</template>
						</o-table-column>

						<o-table-column field="Job" label="Job" sortable searchable>
							<template v-slot:searchable="props">
								<o-input
									v-model="props.filters[props.column.field]"
									placeholder="Search.."
									icon="search"
									size="small"></o-input>
							</template>
							<template v-slot="props">
								{{ props.row.Job }}
							</template>
						</o-table-column>

						<o-table-column field="Quantity" label="Quantity" sortable v-slot="props">
							{{ props.row.Quantity }} Grilles
						</o-table-column>

						<o-table-column field="CreatedOn" label="Created" sortable v-slot="props">
							<o-button variant="primary" inverted size="small" @click="OpenQuote(props.row.Id)">
								{{ $moment.utc(props.row.CreatedOn).local().format("MMM D YYYY") }}
							</o-button>
						</o-table-column>

						<o-table-column field="Status" label="Status" sortable v-slot="props">
							{{ GetStatus(props.row.Status) }}
						</o-table-column>

						<o-table-column field="Document" label="Document" sortable v-slot="props">
							<a v-if="props.row.Document?.Id" href="#" @click="Download(props.row.Document)">
								{{ props.row.Document.Name }}
							</a>
						</o-table-column>

						<o-table-column field="Action" label="Action" v-slot="props">
							<o-button @click="ActionFn(props.row)">
								{{ ActionName(props.row.Status) }}
							</o-button>
						</o-table-column>
					</o-table>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
	import { Dates } from "@/components/report";
	import { PriceQuote, Schedule } from "@/components/modal";

	export default {
		components: {
			Dates
		},
		props: {},
		emits: [],
		setup() {
			const global = getCurrentInstance()?.appContext.config.globalProperties;
			const $http = inject("$http");
			const $moment = inject("$moment");
			const $success = inject("$success");
			const $error = inject("$error");

			const exporting = ref(false);
			const loading = ref(false);
			const hasRan = ref(false);
			const results = ref([]);
			const dates = ref([]);
			const showJobBoss = ref("All");
			const showPrevious = ref("All");
			const showArchived = ref(false);
			const hasFilter = computed(() => {
				return (
					showJobBoss.value !== "All" || showPrevious.value !== "All" || showArchived.value !== false
				);
			});
			const ResetFilters = () => {
				showJobBoss.value = "All";
				showPrevious.value = "All";
				showArchived.value = false;
			};

			const key = ref(0);

			const filteredResults = computed(function () {
				let filtered = [];
				for (const obj of results.value) {
					filtered.push(obj);
				}
				if (showJobBoss.value === "JobBoss") filtered = filtered.filter((x) => x.JobBoss === true);
				else if (showJobBoss.value === "NonJobBoss")
					filtered = filtered.filter((x) => x.JobBoss === false);

				if (showArchived.value) filtered = filtered.filter((x) => x.Status === 3);
				else filtered = filtered.filter((x) => x.Status !== 3);

				if (showPrevious.value === "Previous")
					filtered = filtered.filter((x) => x.PreviousQuotes > 0);
				else if (showPrevious.value === "NonPrevious")
					filtered = filtered.filter((x) => x.PreviousQuotes === 0);

				return filtered;
			});

			const RunReport = async () => {
				loading.value = true;
				hasRan.value = true;
				try {
					var response = await $http.post("/api/report/quote", {
						dates: dates.value,
						filters: {}
					});
					results.value = response.data;
					loading.value = false;
					return response;
				} catch (err) {
					loading.value = false;
					$error("Failed to run report.");
					return Promise.reject(err);
				}
			};

			// const filteredResults = computed(() => {
			//   const holdresults = JSON.parse(JSON.stringify(results));
			// });

			const Export = async () => {
				exporting.value = true;
				try {
					var response = await $http({
						method: "post",
						url: "/api/report/quote/export",
						data: { rows: results.value },
						responseType: "arraybuffer"
					}); //await $http.post("/api/report/quote/export", { rows: results.value });
					const blob = new Blob([response.data], { type: "text/xlsx" });
					var link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.setAttribute(
						"download",
						"RFQ Report " + global.$moment.utc().format("MMM D YYYY") + ".xlsx"
					);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);

					exporting.value = false;
					return response;
				} catch (err) {
					exporting.value = false;
					$error("Failed to export.");
					return Promise.reject(err);
				}
			};

			const Download = async (row) => {
				try {
					const response = await $http.get("/api/document/" + row.Id, {
						responseType: "blob"
					});

					const blob = response.data;
					var link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.setAttribute("download", row.Name);

					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				} catch (err) {
					console.error(err);
				}
			};

			const Limit = (text, limit) => {
				return text.length > limit ? text.substring(0, limit) + "..." : text;
			};

			const jbToggle = async (row) => {
				key.value = Math.random();
				try {
					row.JobBoss = !row.JobBoss;

					await $http.post("/api/report/quote/jbToggle", {
						row: row
					});
					$success("Toggle successful.");
				} catch (err) {
					row.JobBoss = !row.JobBoss;
					$error("Failed to toggle JobBoss status.");
				}
				key.value = Math.random();
			};

			const GetStatus = (statusId) => {
				switch (statusId) {
					case 1:
						return "Open";
					case 2:
						return "Priced";
					case 3:
						return "Archived";
					default:
						return "Error";
				}
			};
			const ActionName = (statusId) => {
				switch (statusId) {
					case 1:
						return "Price";
					case 2:
						return "Archive";
					case 3:
						return "Un-Archive";
					default:
						return "Error";
				}
			};
			const ActionFn = (row) => {
				switch (row.Status) {
					case 1:
						OpenPriceQuote(row.Id);
						break;
					case 2:
						ArchiveQuote(row);
						break;
					case 3:
						UnArchiveQuote(row);
						break;
					default:
						break;
				}
			};

			const OpenPriceQuote = (Id) => {
				global.$oruga.modal.open({
					parent: this,
					component: PriceQuote,
					trapFocus: true,
					active: true,
					props: {
						title: "Price a Quote",
						description: "Input Prices for Grilles",
						Id: Id
					},
					events: {
						close: () => {
							key.value = Math.random();
						}
					}
				});

				//Make PDF with Quote
				//Send PDF in email to User

				//change Quote StatusId
			};

			const GetGrilles = async (quoteId) => {
				try {
					const response = await $http.get(`/api/quote/${quoteId}`);
					return response.data;
				} catch (err) {
					//
				}
			};

			const OpenQuote = async (id) => {
				const g = await GetGrilles(id);
				console.log(g);
				global.$oruga.modal.open({
					parent: this,
					component: Schedule,
					trapFocus: true,
					active: true,
					props: {
						grilles: g.Grilles
					},
					events: {
						save: (result) => {
							result.close();
						}
					},
					onClose(result) {
						//
					}
				});
			};

			const ArchiveQuote = async (row) => {
				try {
					const response = await $http.post("/api/report/quote/archive", {
						row: row
					});
					row.Status = 3;
					$success("Quote archived.");
				} catch (e) {
					$error("Failed to archive");
				}
				key.value = Math.random();
			};

			const UnArchiveQuote = async (row) => {
				key.value = Math.random();
				try {
					var response = await $http.post("/api/report/quote/unarchive", {
						row: row
					});
					row.Status = 2;
					$success("Quote unarchived.");
				} catch (e) {
					$error("Failed to unarchive");
				}
				key.value = Math.random();
			};

			return {
				exporting,
				loading,
				hasRan,
				results,
				dates,
				showJobBoss,
				showPrevious,
				showArchived,
				hasFilter,
				key,
				filteredResults,
				ResetFilters,
				RunReport,
				Export,
				Limit,
				jbToggle,
				GetStatus,
				ActionName,
				ActionFn,
				OpenQuote,
				Download
			};
		}
	};
</script>
<style lang="scss">
	@import "@/assets/css/globalVars.scss";

	.rfq-actions {
		& button {
			position: relative !important;
			top: 2em;
			margin-right: 1em;
		}

		& .switch {
			position: relative !important;
			top: 0.5em;
		}

		& .select-wrapper {
			display: flex;
			flex-direction: row;
			gap: 1em;

			& .select {
				display: inline-block;
			}
		}
	}
</style>
