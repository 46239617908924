<template>
	<div class="dashBody">
		<router-link :to="{ name: 'rfq' }" class="gridBox dashButton requestAQuoteBox">
			<h2>Request a&nbsp;Quote</h2>
		</router-link>

		<router-link :to="{ name: 'schedules' }" class="gridBox dashButton savedSchedulesBox">
			<span class="previewBox">
				<savedSchedules class="previewBoxNumber" is-summary></savedSchedules>
				<!--<o-icon class="dashboardIcon" icon="file-alt"></o-icon>-->
			</span>
			<h2 class="previewBoxLabel">Saved Schedules</h2>
		</router-link>

		<router-link :to="{ name: 'documents' }" class="gridBox dashButton documentsBox">
			<!-- <div class="gridBox dashButton documentsBox"> -->
			<span class="previewBox">
				<savedDocuments class="previewBoxNumber" is-summary></savedDocuments>
				<!--<o-icon class="dashboardIcon" icon="file-alt"></o-icon>-->
			</span>
			<h2 class="previewBoxLabel">Documents</h2>
			<!-- </div> -->
		</router-link>

		<div class="gridBox uploadBox">
			<h2>Upload new document</h2>
			<o-field class="file">
				<o-upload
					v-model="file"
					accept="image/png, image/jpeg, application/pdf, application/msword"
					drag-drop>
					<section class="ex-center">
						<p>
							<o-icon icon="upload" size="is-large"></o-icon>
						</p>
						<p>Drop your files here or click to upload</p>
					</section>
					<!-- //where are our colors set up? we need to make one for primary. -->
				</o-upload>
				<span class="file-name" v-if="file">{{ file.name }}</span>
			</o-field>
			<h2>Saved Documents</h2>
			<savedDocuments ref="docs"></savedDocuments>
		</div>
		<!--<div class="gridBox calculatorsBox"><h2>Calculators</h2></div>-->

		<div class="gridBox quoteHistoryBox">
			<h2>Quote History</h2>
			<quoteHistory></quoteHistory>
		</div>
	</div>
</template>

<script>
	import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
	import useValidate from "@vuelidate/core";
	import { required, email } from "@vuelidate/validators";
	import { savedDocuments, quoteHistory, savedSchedules } from "@/components";
	//import { PhoneNumber } from "@/components/input";
	export default {
		components: {
			savedDocuments,
			quoteHistory,
			savedSchedules
			//PhoneNumber,
		},
		props: {},
		emits: [],
		setup() {
			const global = getCurrentInstance()?.appContext.config.globalProperties;
			const $http = inject("$http");
			const $success = inject("$success");
			const $error = inject("$error");
			const loading = ref(true);
			const states = ref([]);
			const user = ref({});
			const file = ref();

			const v = useValidate(
				{
					user: {
						UserName: {
							required,
							email
						},
						Profile: {
							FirstName: {
								required
							},
							LastName: {
								required
							},
							Phone: {
								AtleastOne() {
									return !!user.value.Profile.Phone || !!user.value.Profile.Fax;
								}
							},
							Fax: {
								AtleastOne() {
									return !!user.value.Profile.Phone || !!user.value.Profile.Fax;
								}
							}
						}
					}
				},
				{ user }
			);

			const GetStates = async () => {
				try {
					var response = await $http.get("/api/state");
					states.value = response.data;
					return response;
				} catch (err) {
					$error("Failed to get states.");
				}
			};

			const GetUser = async () => {
				try {
					var response = await $http.get("/api/user");
					user.value = response.data;
					return response;
				} catch (err) {
					$error("Failed to get user.");
				}
			};

			const Save = async () => {
				try {
					var response = await $http.post("/api/user/admin/update", user.value);
					if (response.data.User.Id) {
						$success("Successfully updated the user's info.");
					} else {
						$error("Failed to save.");
					}
					return response;
				} catch (err) {
					$error("Failed to save.");
				}
			};
			const UploadFile = async () => {
				setTimeout(async () => {
					try {
						var formData = new FormData();
						formData.append("file", file.value);
						var response = await $http.post("/api/document/upload", formData, {
							headers: { "Content-Type": "multipart/form-data" }
						});
						file.value = null;

						if (response.data.Id) {
							$success("The file was successfully uploaded.");
						} else {
							$error("Failed to upload file.");
						}
						return response;
					} catch (err) {
						$error("Failed to upload file.");
					}
				}, 100);
			};
			/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

			onMounted(async () => {
				await GetStates();
				await GetUser();
				loading.value = false;
				//
			});

			watch(
				() => file.value,
				(n, o) => {
					if (n && n !== o) {
						UploadFile();
					}
				}
			);

			return {
				v,
				loading,
				states,
				user,
				file,
				GetStates,
				GetUser,
				Save,
				UploadFile
			};
		}
	};
</script>

<style lang="scss">
	@import "@/assets/css/globalVars.scss";
	$dash-break-small: 36.12rem;
	$dash-break-medium: 62.12rem;
	$dash-break-large: 85.12rem;
	$dash-gap: 1em;
	$dash-row-height: 6em;

	.dashBody {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: repeat(auto, auto);
		grid-template-areas: "rfq" "scheds" "docs" "quotes" "upload" "calcs";
		grid-gap: $dash-gap;
		margin: 0 auto;
		padding: $dash-gap $dash-gap 0 $dash-gap;
		//background-color: $background;
		// background-image: url("./../assets/AAGImages/s5.png");

		@media screen and (min-width: $dash-break-small) {
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 4em auto;
			grid-template-areas: "rfq    rfq" "scheds docs" "quotes quotes" "upload upload" "calcs  calcs";
			width: 100%;
		}

		@media screen and (min-width: $dash-break-medium) {
			grid-template-columns: 1.1fr 1fr 1fr 1fr;
			grid-template-rows: 10em auto;
			grid-template-areas: "rfq    scheds docs upload" "quotes quotes quotes upload" "quotes quotes quotes upload";
			width: 100%;
		}

		@media screen and (min-width: $dash-break-large) {
			grid-template-columns: 1.1fr 1fr 1fr 1fr 1.5fr;
			grid-template-rows: 10em auto;
			grid-template-areas: "rfq    scheds docs grills    upload" "quotes quotes quotes quotes upload" "quotes quotes quotes quotes calcs ";
			width: $dash-break-large - 1rem;
		}
	}

	.previewBox {
		font-size: 3em;
		vertical-align: center;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		& .previewBoxNumber {
			font-size: 2em;
			font-family: sans-serif;
		}
	}

	.dashboardIcon {
		//display: inline-block;
		margin-left: 0.2em;
	}

	.dashHeader {
		font-size: 1em;
		text-align: center;
		background-color: $secondary;
		border-radius: 10px;
	}

	.dashButton {
		background-color: $primary;
		color: #fafafa;
		transition: transform ease-in-out 0.1s 0s, box-shadow ease-in-out 0.1s 0s;

		&:hover {
			transform: scale(1.02);
			background-color: lighten($primary, 10%);
			color: darken($primary, 10%);
		}
	}

	.gridBox {
		// background-color: $secondary;
		border-radius: $radius-large;
		display: flex;
		flex-direction: column;
		text-align: center;
		justify-content: center;
		box-shadow: $shadow;

		& .previewBoxLabel {
			position: relative;
			bottom: 1em;
		}
	}

	////////// top //////////
	.requestAQuoteBox {
		grid-area: rfq;
		height: 6em;
		background-color: $special;
		color: white;

		& h2 {
			line-height: 1.1em;
			font-size: 2em;
		}

		&:hover {
			background-color: lighten($special, 10%);
			color: darken($special, 10%);
		}

		@media screen and (min-width: $dash-break-small) {
			height: auto;
		}
	}

	.savedSchedulesBox {
		grid-area: scheds;
	}

	.savedGrillesBox {
		grid-area: grills;
	}

	.documentsBox {
		grid-area: docs;
	}

	////////// utilities //////////
	.uploadBox {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		grid-area: upload;
		padding: 1em;
		background-color: $boxColor;
		height: fit-content;

		& section.ex-center {
			width: 100%;
		}

		& .o-upl {
			border: 1px dashed black;

			&__draggable {
				border: 1em solid transparent !important;
				border-width: 4em 1em !important;
			}
		}

		& .field.has-addons {
			flex-direction: column;
		}
	}

	.calculatorsBox {
		display: grid;
		grid-area: calcs;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		grid-gap: $dash-gap;
		background-color: $boxColor;
	}

	////////// Quote History //////////
	.quoteHistoryBox {
		grid-area: quotes;
		background-color: $boxColor;
		padding-bottom: $dash-gap;
	}
</style>



<!--

<style lang="scss">
    @import "@/assets/css/globalVars.scss";
    $dash-break-large: 102.12rem;
    $dash-gap: 1em;


    .dashBody {
        display: grid;
        // grid-template-columns: 2fr 6fr 3fr;
        grid-template-columns: 3fr 9fr 4fr;
        grid-template-rows: 13em 36em; //repeat(4, 10em);
        grid-gap: $dash-gap;
        margin: 0 auto;
        padding: 3em 0em 0 0em;
        width: 100rem;
        //background-color: $background;
        // background-image: url("./../assets/AAGImages/s5.png");
    }

    .previewBox {
        font-size: 3em;
        vertical-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        & .previewBoxNumber {
            font-size: 2em;
            font-family: sans-serif;
        }
    }

    .dashboardIcon {
        //display: inline-block;
        margin-left: 0.2em;
    }

    .dashHeader {
        font-size: 1em;
        text-align: center;
        background-color: $secondary;
        border-radius: 10px;
    }

    .gridBox {
        // background-color: $secondary;
        border-radius: $radius-large;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        box-shadow: $shadow;
    }

    ////////// top //////////
    .requestAQuoteBox {
        background-color: $special;
        color: $background;
        grid-column: 1;
        grid-row: 1;
    }

    .dashNumbers {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-gap: $dash-gap;
    }

    .savedSchedulesBox {
        background-color: $boxColor;
        grid-column: 1;
        grid-row: 1;
    }

    .savedGrillesBox {
        background-color: $boxColor;
        grid-column: 2;
        grid-row: 1;
    }

    .documentsBox {
        background-color: $boxColor;
        grid-column: 3;
        grid-row: 1;
    }

    ////////// utilities //////////
    .utilities {
        grid-column: 3;
        grid-row: 1 / span 2;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: $dash-gap;
        // padding: 1em;
    }

    .uploadBox {
        background-color: $boxColor;
        grid-column: 1;
        grid-row: 1;
    }

    .calculatorsBox {
        background-color: $boxColor;
        grid-column: 1;
        grid-row: 2;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: $dash-gap;
    }

    ////////// Quote History //////////
    .quoteHistoryBox {
        background-color: $boxColor;
        grid-column: 1 / span 2;
        grid-row: 2;
    }
</style>

    -->
